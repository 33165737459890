import React from 'react'
import { clientData, clientData2, clientData3, clientData4, clientData5, clientData6 } from '../../data/clientData'

import './Clients.css'

export const Clients = () => {
  return (
    <div className='clients-container'>
        <div className='row-container'>
            {clientData.map((clientData, index) => {
                return <img src={clientData.img} onMouseEnter={e => e.currentTarget.src=clientData.hoverImg} onMouseOut={e => e.currentTarget.src=clientData.img} key={index} alt=''/>
            })}
        </div>
        <div className='row-container second'>
            {clientData2.map((clientData2, index) => {
                return <img src={clientData2.img} onMouseEnter={e => e.currentTarget.src=clientData2.hoverImg} onMouseOut={e => e.currentTarget.src=clientData2.img} key={index} alt=''/>
            })}
        </div>
        <div className='row-container'>
            {clientData3.map((clientData3, index) => {
                return <img src={clientData3.img} onMouseEnter={e => e.currentTarget.src=clientData3.hoverImg} onMouseOut={e => e.currentTarget.src=clientData3.img} key={index} alt=''/>
            })}
        </div>
        <div className='row-container'>
            {clientData4.map((clientData4, index) => {
                return <img src={clientData4.img} onMouseEnter={e => e.currentTarget.src=clientData4.hoverImg} onMouseOut={e => e.currentTarget.src=clientData4.img} key={index} alt=''/>
            })}
        </div>
        <div className='row-container'>
            {clientData5.map((clientData2, index) => {
                return <img src={clientData2.img} onMouseEnter={e => e.currentTarget.src=clientData2.hoverImg} onMouseOut={e => e.currentTarget.src=clientData2.img} key={index} alt=''/>
            })}
        </div>
        <div className='row-container'>
            {clientData6.map((clientData3, index) => {
                return <img src={clientData3.img} onMouseEnter={e => e.currentTarget.src=clientData3.hoverImg} onMouseOut={e => e.currentTarget.src=clientData3.img} key={index} alt=''/>
            })}
        </div>
    </div>
  )
}
