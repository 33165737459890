import { useState, useLayoutEffect } from "react";
import "./ClientsPage.css";

import TabSwitchGrid from "../components/TabSwitchGrid/TabSwitchGrid";

import { Navbar, SideNav } from "../components/Navbar/Navbar";
import { FooterFormClients } from "../components/FooterFormClients/FooterFormClients";
import { NumberBar } from "../components/NumberBar/NumberBar";
import { SliderCarruselClients } from "../components/SliderCarruselClients/SliderCarruselClients";

export const Clients = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScrollThreshold(window.innerHeight * 1.2);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold]);

  return (
    <>
      <div className="desktop">
        <Navbar />
      </div>
      <div className="mobile">
        <SideNav />
      </div>
      <SliderCarruselClients />
      <div className="tabSwitchGrid-container">
        <TabSwitchGrid />
      </div>
      {isVisible && <NumberBar />}
      {!isVisible && <FooterFormClients />}
    </>
  );
};
