import React from "react";

import "./ClientPage.css";
import { useState, useLayoutEffect } from "react";

import { Navbar, SideNav } from "../components/Navbar/Navbar";
import { FooterForm } from "../components/FooterForm/FooterForm";
import { NumberBar } from "../components/NumberBar/NumberBar";
import { ClientBanner } from "../components/ClientBanner/ClientBanner";
import { ClientSliderGrid } from "../components/ClientSliderGrid/ClientSliderGrid";

export const ClientPage = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScrollThreshold(window.innerHeight * 0.4);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold]);

  return (
    <>
      <div className="desktop">
        <Navbar />
      </div>
      <div className="mobile">
        <SideNav />
      </div>
      <ClientBanner />
      <ClientSliderGrid />
      {isVisible && <NumberBar />}
      <FooterForm />
    </>
  );
};
