import { useState, useLayoutEffect } from "react";

import { Navbar, SideNav } from "../components/Navbar/Navbar";
import { Clients } from "../components/Clients/Clients";
import { FooterForm } from "../components/FooterForm/FooterForm";
import { NumberBar } from "../components/NumberBar/NumberBar";

import OurBanner from "../assets/our/OurBanner.png";

import ourPeople from "../data/ourPeopleData";

import "./OurPeople.css";

export const OurPeople = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScrollThreshold(window.innerHeight * 1);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold]);

  return (
    <>
      <div className="desktop">
        <Navbar />
      </div>
      <div className="mobile">
        <SideNav />
      </div>
      <div className="OurPeople-container">
        <div className="OurPoeple-banner">
          <img src={OurBanner} alt="ourBanner" />
        </div>
        <div className="OurPeople-textContainer">
          <h1>PERSONAS DE CALIDAD MUNDIAL</h1>
          <p>
            En China, todos los días, alrededor de las 10 de la mañana, los
            equipos de trabajo salen a la calle a hablar de los objetivos del
            día, de las fallas del día anterior y de como completaran las tareas
            pendientes. <br />
            Esta disciplina China se repite en México todos los días con mis “8
            de la buena fortuna”. Ellos son mis Colaboradores, trabajan con una
            disciplina tan estricta como la oriental. Sin saberlo, mis
            colaboradores y yo, ya hacíamos esta reunión callejera… trabajamos
            en las calles, nuestros objetivos están exactamente afuera de las
            grandes fachadas de las distintas empresas con las que colaboramos,
            ahí planeamos y ejecutamos. <br />
            <br />
            Con puntualidad, valores, comprometidos y con los mas altos
            estándares de “minuciosidad” (como en China). Sin importar el tipo
            de fachada en la que tengamos que trabajar, ya sea de una agencia
            automotriz, una gran tienda departamental, grandes escaparates o
            caprichosas estructuras, estos “8 de la buena fortuna” se rifan
            notablemente frente al sol, alturas inimaginables, lluvias, horarios
            caprichosos para dejar siempre a nuestros clientes más que
            satisfechos.
            <br />
            <br /> Mis HONORES a estos 8 bravos que colaboran para que nuestros
            clientes puedan seguir certificándose por las plantas armadoras más
            prestigiosas del mundo, son impecables, son disciplinados. Si no
            tuvieran rasgos evidentemente mexicanos, diría que pertenecen a una
            cultura oriental. Para ser un profesionista se requiere un titulo
            universitario, pero para ser un profesional se requiere orden,
            pulcritud, puntualidad, exactitud y una actitud de colaboración
            intachable… ellos tienen todo esto y además, virtudes técnicas que
            los hacen inmejorables. Gracias a estos extraordinarios
            profesionales: Arturo Rodríguez "El Ardi", Jorge Díaz de León
            "George", Carlos Martínez "Carlitos", Juan Palomera, Verónica
            Maldonado, Mario Galván, Juan Bosco, Domingo y Luis Humberto “Beto”.
            Si tú, amigo lector, no tienes a tus “8 de la buena fortuna”, te
            recomiendo ampliamente comenzar con tus colaboradores para lograrlo.
            Estoy seguro de que en china son más, pero ¡en México
            podemos ser mejores!
          </p>
        </div>
        <div className="OurPeople-galeryContainer">
          {ourPeople.map((item, index) => {
            return <img src={item.img} alt="img" key={index} />;
          })}
        </div>
        <Clients />
      </div>
      {isVisible && <NumberBar />}
      <FooterForm />
    </>
  );
};
