import ChevroletFondo from '../assets/prDone/ChevroletFondo.png'
import FlexFondo from '../assets/prDone/FlexFondo.png'
import KiaFondo from '../assets/prDone/KiaFondo.png'

const sliderData = [
    {
        img: ChevroletFondo,
        alt: 'ChevroletFondoImagen'
    },
    {
        img: FlexFondo,
        alt: 'FlexFondoImagen'
    },
    {
        img: KiaFondo,
        alt: 'KiaFondoImagen'
    },
]

export default sliderData;