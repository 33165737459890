import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./ClientSlider.css";

export const ClientSlider = ({ sliderImgs }) => {
  return (
    <>
      <Swiper className="mySwiper4">
        {sliderImgs.map((img) => (
          <SwiperSlide>
            <img src={img} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
