//section 1
import Flex from '../assets/clients/Flex_byn.png'
import FlexColor from '../assets/clients/Flex_color.png'
import Maver from '../assets/clients/Maver_byn.png'
import MaverColor from '../assets/clients/Maver_color.png'
import Fresenius from '../assets/clients/Fresenius_byn.png'
import FreseniusColor from '../assets/clients/Fresenius_color.png'
import CFE from '../assets/clients/CFE_byn.png'
import CFEColor from '../assets/clients/CFE_color.png'
import MonteDePiedad from '../assets/clients/Monte-de-piedad_byn.png'
import MonteDePiedadColor from '../assets/clients/Monte-de-piedad_color.png'
import CUCEA from '../assets/clients/CUCEA_byn.png'
import CUCEAColor from '../assets/clients/CUCEA_color.png'
import UPC from '../assets/clients/UPC_byn.png'
import UPCColor from '../assets/clients/UPC_color.png'

//section2
import FC from '../assets/clients/FC_byn.png'
import FCColor from '../assets/clients/FC_color.png'
import VOIT from '../assets/clients/VOIT_byn.png'
import VOITColor from '../assets/clients/VOIT_color.png'
import Chopo from '../assets/clients/Chopo_byn.png'
import ChopoColor from '../assets/clients/Chopo_color.png'
import GIG from '../assets/clients/GIG_byn.png'
import GIGColor from '../assets/clients/GIG_color.png'
import EGDL from '../assets/clients/EGDL_byn.png'
import EGDLColor from '../assets/clients/EGDL_color.png'
import Radec from '../assets/clients/Radec_byn.png'
import RadecColor from '../assets/clients/Radec_color.png'

//section3

import Ardilla from '../assets/clients/Ardilla_byn.png'
import ArdillaColor from '../assets/clients/Ardilla_color.png'
import Oxiteno from '../assets/clients/Oxiteno_byn.png'
import OxitenoColor from '../assets/clients/Oxiteno_color.png'
import Radial from '../assets/clients/Radial_byn.png'
import RadialColor from '../assets/clients/Radial_color.png'
import Almer from '../assets/clients/Almer_byn.png'
import AlmerColor from '../assets/clients/Almer_color.png'
import CID from '../assets/clients/CID_byn.png'
import CIDColor from '../assets/clients/CID_color.png'
import Gonbar from '../assets/clients/Gonbar_byn.png'
import GonbarColor from '../assets/clients/Gonbar_color.png'
import Copachisa from '../assets/clients/Copachisa_byn.png'
import CopachisaColor from '../assets/clients/Copachisa_color.png'

//section4

import ATLAS from '../assets/clients/ATLAS_byn.png'
import ATLASColor from '../assets/clients/ATLAS_color.png'
import FONACOT from '../assets/clients/FONACOT_byn.png'
import FONACOTColor from '../assets/clients/FONACOT_color.png'
import Terra from '../assets/clients/Terra_byn.png'
import TerraColor from '../assets/clients/Terra_color.png'
import Elviejito from '../assets/clients/El-viejito_color.png'
import Mercabastos from '../assets/clients/Mercabastos_byn.png'
import MercabastosColor from '../assets/clients/Mercabastos_color.png'
import Espolon from '../assets/clients/Espolon_byn.png'
import EspolonColor from '../assets/clients/Espolon_color.png'

//section5

import Volvo from '../assets/clients/Volvo_byn.png'
import VolvoColor from '../assets/clients/Volvo_color.png'
import Toyota from '../assets/clients/Toyota_byn.png'
import ToyotaColor from '../assets/clients/Toyota_color.png'
import Nissan from '../assets/clients/Nissan_color.png'
import Hyundai from '../assets/clients/hyundai_color.png'
import Suzuki from '../assets/clients/Suzuki_byn.png'
import SuzukiColor from '../assets/clients/Suzuki_color.png'
import Buik from '../assets/clients/Buik_byn.png'
import BuikColor from '../assets/clients/Buik_color.png'

//section6

import Kia from '../assets/clients/Kia_color.png'
import ChevroletColor from '../assets/clients/Chevrolet_color.png'
import Chevrolet from '../assets/clients/Chevrolet_byn.png'
import Ford from '../assets/clients/Ford_byn.png'
import FordColor from '../assets/clients/Ford_color.png'

export const clientData = [
    {
        img: Flex,
        hoverImg: FlexColor
    },
    {
        img: Maver,
        hoverImg:MaverColor
    },
    {
        img: Fresenius,
        hoverImg:FreseniusColor
    },
    {
        img: CFE,
        hoverImg:CFEColor
    },
    {
        img: MonteDePiedad,
        hoverImg:MonteDePiedadColor
    },
    {
        img: CUCEA,
        hoverImg:CUCEAColor
    },
    {
        img: UPC,
        hoverImg:UPCColor
    },
]

export const clientData2 = [
    {
        img: FC,
        hoverImg:FCColor
    },
    {
        img: VOIT,
        hoverImg:VOITColor
    },
    {
        img: Chopo,
        hoverImg:ChopoColor
    },
    {
        img: GIG,
        hoverImg:GIGColor
    },
    {
        img: EGDL,
        hoverImg:EGDLColor
    },
    {
        img: Radec,
        hoverImg:RadecColor
    },
]

export const clientData3 = [
    {
        img: Ardilla,
        hoverImg:ArdillaColor
    },
    {
        img: Oxiteno,
        hoverImg:OxitenoColor
    },
    {
        img: Radial,
        hoverImg:RadialColor
    },
    {
        img: Almer,
        hoverImg:AlmerColor
    },
    {
        img: CID,
        hoverImg:CIDColor
    },
    {
        img: Gonbar,
        hoverImg:GonbarColor
    },
    {
        img: Copachisa,
        hoverImg:CopachisaColor
    }
]

export const clientData4 = [
    {
        img: ATLAS,
        hoverImg:ATLASColor
    },
    {
        img: FONACOT,
        hoverImg:FONACOTColor
    },
    {
        img: Terra,
        hoverImg:TerraColor
    },
    {
        img: Elviejito,
        hoverImg:Elviejito
    },
    {
        img: Mercabastos,
        hoverImg:MercabastosColor
    },
    {
        img: Espolon,
        hoverImg:EspolonColor
    }
]

export const clientData5 = [
    {
        img: Volvo,
        hoverImg:VolvoColor
    },
    {
        img: Toyota,
        hoverImg:ToyotaColor
    },
    {
        img: Nissan,
        hoverImg:Nissan
    },
    {
        img: Hyundai,
        hoverImg:Hyundai
    },
    {
        img: Suzuki,
        hoverImg:SuzukiColor
    },
    {
        img: Buik,
        hoverImg:BuikColor
    }
]

export const clientData6 = [
    {
        img: Kia,
        hoverImg:Kia
    },
    {
        img: Chevrolet,
        hoverImg:ChevroletColor
    },
    {
        img: Ford,
        hoverImg:FordColor
    }
]

