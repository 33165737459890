import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

import useBannerData from "../../data/usBannerData";

import "./UsBanner.css";

export const UsBanner = () => {
  var screenWidth = window.innerWidth;
  return (
    <Swiper
      spaceBetween={50}
      effect={"fade"}
      navigation={screenWidth > 1620 ? true : false}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      slidesPerView={3}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      className="usSlider"
    >
      {useBannerData.map((banner) => {
        return (
          <SwiperSlide>
            <img className="slideImage" src={banner.img} alt={banner.alt} />
            <div className="usBanner-content-inside">
              <div className="usBanner-text-container">
                <p>
                  Somos una empresa mexicana con experiencia de hace mas de 15
                  años en el ramo de la Construcción siendo proveedores de
                  soluciones de limpieza para fachadas monumentales, donde nos
                  hemos consolidado de una manera solida a nivel regional
                </p>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
