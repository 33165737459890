import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

import sliderData from "../../data/sliderData";

import "./SliderCarrusel.css";

export const SliderCarrusel = () => {
  var screenWidth = window.innerWidth;
  return (
    <Swiper
      spaceBetween={50}
      effect={"fade"}
      navigation={screenWidth > 1620 ? true : false}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      slidesPerView={3}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      className="homeSlider"
    >
      {sliderData.map((banner) => {
        return (
          <SwiperSlide className="home-swiper-slide">
            <img className="slideImage" src={banner.img} alt={banner.alt} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
