import React from "react";
import "./FooterForm.css";
import Form from "../Form/Form";

import FlexBanner from "../../assets/grid/Flex/FlexBanner.png";

export const FooterForm = () => {
  return (
    <div className="footerform-container">
      <div className="footerform-formpart">
        <Form />
      </div>
      <div className="footerform-contact-container">
        <div className="footerform-contact-image">
          <div className="whatsapp-container">
            <div className="text-contact-container">
              Contactanos para un servicio personalizado
            </div>
            <button>
              <a
                className="whats-button"
                href="https://wa.me/+523326359211/?text=test"
              >
                Whatsapp
              </a>
            </button>
          </div>
          <img src={FlexBanner} alt="footerImage"></img>
        </div>
        <div className="footerform-contact-info">
          33 1031 5589 <br />
          ventas@cleaningsystems.mx <br />
          Zapopan, Jalisco
        </div>
      </div>
    </div>
  );
};
