import React from 'react';

import {gridData} from '../../data/gridData'

import { Link } from 'react-router-dom';

import './TabSwitchGrid.css'

export const TabSwitchGrid = () => {
  return (
    <div className ="grid-container">
      {gridData.map((gridData, index) => (
        <Link to={`/clients/${gridData.id}`} >
          <div className = "individual-grid">
              <div className='indiv-img-container'>
                  <img key={index} src={gridData.img} alt=''/>
              </div>
              <div className = "grid-overlay">
                  <img key={index} src={gridData.hoverImg} alt=''/>
              </div>
          </div>
        </Link >
      ))}
    </div>
  );
};

export default TabSwitchGrid;