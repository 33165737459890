import Galeria3 from '../assets/our/Galeria3.png'
import Galeria2 from '../assets/our/Galeria2.png'
import Galeria1 from '../assets/our/Galeria1.png'


export const ourPeople = [
    {
        img: Galeria3
    },
    {
        img: Galeria2
    },
    {
        img: Galeria1
    }
]

export default ourPeople;