import React from 'react'

import './ClientSliderGrid.css'

import { ClientSlider } from '../ClientsSlider/ClientSlider';

import {gridData} from '../../data/gridData'
import { useParams } from 'react-router-dom';


export const ClientSliderGrid = () => {
    const { id } = useParams();
    const clientData = gridData.find((data) => data.id === Number(id));
    
    const proyectoKeys = Object.keys(clientData ?? {}).filter(
      (key) => key.includes("proyecto") && clientData[key].length > 0
    );
    return (
      <div className="CLG-container">
        <div className="clientSliderGrid-container">
          {proyectoKeys.map((proyectoKey, index) => (
            <div className="clientSliderGrid-slider" key={index}>
              <h1>{clientData[proyectoKey][0].nombreProyecto}</h1>
              <ClientSlider sliderImgs={clientData[proyectoKey][0].sliderImgs} />
            </div>
          ))}
        </div>
      </div>
    );
  };
