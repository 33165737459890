import "./Us.css";
import { useState, useLayoutEffect } from "react";

import { Navbar, SideNav } from "../components/Navbar/Navbar";
import { Clients } from "../components/Clients/Clients";
import { FooterForm } from "../components/FooterForm/FooterForm";
import { NumberBar } from "../components/NumberBar/NumberBar";
import { UsBanner } from "../components/UsBanner/UsBanner";

import Descripcion from "../assets/us/usAssets/Descripcion.jpg";

import "./Us.css";

export const Us = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScrollThreshold(window.innerHeight * 1.7);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold]);
  return (
    <div className="usPage-container">
      <div className="desktop">
        <Navbar />
      </div>
      <div className="mobile">
        <SideNav />
      </div>
      <UsBanner />
      <div className="usPage-text-container">
        <p>
          Debido la necesidad en dar una limpieza profesional a Fachadas
          monumentales, específicamente con Panel Compuesto de Aluminio, Paneles
          Fenólicos y Cristales, surge nuestra División de Limpieza y
          Mantenimiento <b>CLEANING SYSTEMS.</b>
        </p>
        <p>
          La experiencia que se tiene siendo uno de los principales proveedores
          de servicios para la limpieza de estos materiales, nos lleva a
          desarrollar esta nueva División que nos permite llegar a nuevos nichos
          de mercado, como son:
          <b>
            RACKS DE ALMACENAMIENTO, ESTRUCTURAS DE BODEGAS, PISOS TECNICOS,
            PANELES SOLARES, FACHADAS DE CRISTAL.
          </b>
        </p>
      </div>
      <div className="usPage-image-info-container">
        <div className="usPage-image-container">
          <img src={Descripcion} alt="descripcion" />
        </div>
        <div className="usPage-info-container">
          <p>
            La mayoría de los usuarios no pasan tiempo buscando en sus techos
            las acumulaciones de polvo. La mayoría no nota la acumulación de
            polvo hasta que comienza a caer en su maquinaria de producción e
            incluso en sus productos terminados. En la industria alimenticia,
            farmacéutica, Tecnología, etc., puede resultar en una inspección
            fallida por parte de la autoridad. <br />
            <br />
            Además, en los entornos de fabricación, almacenamiento y
            comerciales, puede convertirse en una neblina de seguridad, salud o
            fuego, especialmente si se trata de polvo combustible.
          </p>
        </div>
      </div>
      <Clients />
      {isVisible && <NumberBar />}
      {!isVisible && <FooterForm />}
    </div>
  );
};
