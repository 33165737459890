import Flex from "../assets/clients/Flex_byn.png";
import FlexBanner from "../assets/grid/Flex/FlexBanner.png";
import SliderFlex1_1 from "../assets/grid/Flex/Desincrustado/SliderFlex1_1.png";
import SliderFlex1_2 from "../assets/grid/Flex/Desincrustado/SliderFlex1_2.png";
import SliderFlex1_3 from "../assets/grid/Flex/Desincrustado/SliderFlex1_3.png";
import SliderFlex1_4 from "../assets/grid/Flex/Desincrustado/SliderFlex1_4.jpeg";
import SliderFlex1_5 from "../assets/grid/Flex/Desincrustado/SliderFlex1_5.jpeg";

import SliderFlex2_1 from "../assets/grid/Flex/Fachada/SliderFlex2_1.png";
import SliderFlex2_2 from "../assets/grid/Flex/Fachada/SliderFlex2_2.png";
import SliderFlex2_3 from "../assets/grid/Flex/Fachada/SliderFlex2_3.png";
import SliderFlex2_4 from "../assets/grid/Flex/Fachada/SliderFlex2_4.png";
import SliderFlex2_5 from "../assets/grid/Flex/Fachada/SliderFlex2_5.png";

import SliderFlex3_1 from "../assets/grid/Flex/NavesIndustriales/SliderFlex3_1.png";
import SliderFlex3_2 from "../assets/grid/Flex/NavesIndustriales/SliderFlex3_2.png";
import SliderFlex3_3 from "../assets/grid/Flex/NavesIndustriales/SliderFlex3_3.png";
import SliderFlex3_4 from "../assets/grid/Flex/NavesIndustriales/SliderFlex3_4.png";
import SliderFlex3_5 from "../assets/grid/Flex/NavesIndustriales/SliderFlex3_5.png";
import SliderFlex3_6 from "../assets/grid/Flex/NavesIndustriales/SliderFlex3_6.png";

import SliderFlex4_1 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_1.jpg";
import SliderFlex4_2 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_2.jpg";
import SliderFlex4_3 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_3.jpg";
import SliderFlex4_4 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_4.jpg";
import SliderFlex4_5 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_5.jpg";
import SliderFlex4_6 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_6.jpg";
import SliderFlex4_7 from "../assets/grid/Flex/PanelesSolares/SliderFlex4_7.jpg";

import SliderFlex5_1 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_1.png";
import SliderFlex5_2 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_2.png";
import SliderFlex5_3 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_3.png";
import SliderFlex5_4 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_4.png";
import SliderFlex5_5 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_5.png";
import SliderFlex5_6 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_6.png";
import SliderFlex5_7 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_7.jpeg";
import SliderFlex5_8 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_8.jpeg";
import SliderFlex5_9 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_9.jpeg";
import SliderFlex5_10 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_10.jpeg";
import SliderFlex5_11 from "../assets/grid/Flex/PisosTecnicos/SliderFlex5_11.jpeg";

import Maver from "../assets/clients/Maver_color.png";
import MaverBanner from "../assets/grid/Maver/MaverBanner.png";
import SliderMaver1_1 from "../assets/grid/Maver/FachadasMonumentales/SliderMaver1_1.png";
import SliderMaver1_2 from "../assets/grid/Maver/FachadasMonumentales/SliderMaver1_2.png";
import SliderMaver1_3 from "../assets/grid/Maver/FachadasMonumentales/SliderMaver1_3.png";
import SliderMaver1_4 from "../assets/grid/Maver/FachadasMonumentales/SliderMaver1_4.png";

import SliderMaver2_1 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_1.png";
import SliderMaver2_2 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_2.png";
import SliderMaver2_3 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_3.png";
import SliderMaver2_4 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_4.jpg";
import SliderMaver2_5 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_5.png";
import SliderMaver2_6 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_6.png";
import SliderMaver2_7 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_7.png";
import SliderMaver2_8 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_8.png";
import SliderMaver2_9 from "../assets/grid/Maver/NavesIndustriales/SliderMaver2_9.png";

import SliderMaver3_1 from "../assets/grid/Maver/PisosTecnicos/SliderMaver3_1.png";

import SliderMaver4_1 from "../assets/grid/Maver/Sanitizacion/SliderMaver4_1.png";
import SliderMaver4_2 from "../assets/grid/Maver/Sanitizacion/SliderMaver4_2.png";
import SliderMaver4_3 from "../assets/grid/Maver/Sanitizacion/SliderMaver4_3.png";
import SliderMaver4_4 from "../assets/grid/Maver/Sanitizacion/SliderMaver4_4.png";
import SliderMaver4_5 from "../assets/grid/Maver/Sanitizacion/SliderMaver4_5.png";
import SliderMaver4_6 from "../assets/grid/Maver/Sanitizacion/SliderMaver4_6.png";

import FrenesiusKavi from "../assets/clients/Fresenius_color.png";
import FrenesiusBanner from "../assets/grid/FrenesiusKavi/FrenesiusBanner.jpg";
import SliderFK1 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK1.jpg";
import SliderFK2 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK2.jpg";
import SliderFK3 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK3.jpg";
import SliderFK4 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK4.jpg";
import SliderFK5 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK5.jpg";
import SliderFK6 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK6.jpg";
import SliderFK7 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK7.jpg";
import SliderFK8 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK8.jpg";
import SliderFK9 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK9.jpg";
import SliderFK10 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK10.jpg";
import SliderFK11 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK11.jpg";
import SliderFK12 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK12.jpg";
import SliderFK13 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK13.jpg";
import SliderFK14 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK14.jpg";
import SliderFK15 from "../assets/grid/FrenesiusKavi/PisosTecnicos/SliderFK15.jpg";

import Kia from "../assets/clients/Kia_color.png";
import KiaBanner from "../assets/grid/Kia/KiaBanner.png";
import SliderKia1_1 from "../assets/grid/Kia/Colima/SliderKia1_1.png";
import SliderKia1_2 from "../assets/grid/Kia/Colima/SliderKia1_2.png";
import SliderKia1_3 from "../assets/grid/Kia/Colima/SliderKia1_3.png";
import SliderKia1_4 from "../assets/grid/Kia/Colima/SliderKia1_4.png";
import SliderKia1_5 from "../assets/grid/Kia/Colima/SliderKia1_5.jpg";
import SliderKia1_6 from "../assets/grid/Kia/Colima/SliderKia1_6.png";

import SliderKia2_1 from "../assets/grid/Kia/Galerias/SliderKia2_1.png";
import SliderKia2_2 from "../assets/grid/Kia/Galerias/SliderKia2_2.png";
import SliderKia2_3 from "../assets/grid/Kia/Galerias/SliderKia2_3.png";
import SliderKia2_4 from "../assets/grid/Kia/Galerias/SliderKia2_4.png";

import SliderKia3_1 from "../assets/grid/Kia/Mazatlan/SliderKia3_1.png";
import SliderKia3_2 from "../assets/grid/Kia/Mazatlan/SliderKia3_2.png";
import SliderKia3_3 from "../assets/grid/Kia/Mazatlan/SliderKia3_3.png";

import SliderKia4_1 from "../assets/grid/Kia/Tepic/SliderKia4_1.png";
import SliderKia4_2 from "../assets/grid/Kia/Tepic/SliderKia4_2.png";
import SliderKia4_3 from "../assets/grid/Kia/Tepic/SliderKia4_3.JPG";
import SliderKia4_4 from "../assets/grid/Kia/Tepic/SliderKia4_4.png";
import SliderKia4_5 from "../assets/grid/Kia/Tepic/SliderKia4_5.JPG";
import SliderKia4_6 from "../assets/grid/Kia/Tepic/SliderKia4_6.png";

import Chevrolet from "../assets/clients/Chevrolet_color.png";
import ChevroletBanner from "../assets/grid/Chevrolet/ChevroletBanner.png";
import SliderChev1_1 from "../assets/grid/Chevrolet/Autonova/SliderChev1_1.jpg";
import SliderChev1_2 from "../assets/grid/Chevrolet/Autonova/SliderChev1_2.jpg";
import SliderChev1_3 from "../assets/grid/Chevrolet/Autonova/SliderChev1_3.jpg";
import SliderChev1_4 from "../assets/grid/Chevrolet/Autonova/SliderChev1_4.jpg";
import SliderChev1_5 from "../assets/grid/Chevrolet/Autonova/SliderChev1_5.jpg";
import SliderChev1_6 from "../assets/grid/Chevrolet/Autonova/SliderChev1_6.jpg";
import SliderChev1_7 from "../assets/grid/Chevrolet/Autonova/SliderChev1_7.jpg";
import SliderChev1_8 from "../assets/grid/Chevrolet/Autonova/SliderChev1_8.jpg";
import SliderChev1_9 from "../assets/grid/Chevrolet/Autonova/SliderChev1_9.jpg";
import SliderChev1_10 from "../assets/grid/Chevrolet/Autonova/SliderChev1_10.jpg";
import SliderChev1_11 from "../assets/grid/Chevrolet/Autonova/SliderChev1_11.jpg";
import SliderChev1_12 from "../assets/grid/Chevrolet/Autonova/SliderChev1_12.jpg";

import SliderChev2_1 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_1.png";
import SliderChev2_2 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_2.png";
import SliderChev2_3 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_3.png";
import SliderChev2_4 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_4.png";
import SliderChev2_5 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_5.png";
import SliderChev2_6 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_6.png";
import SliderChev2_7 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_7.png";
import SliderChev2_8 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_8.jpg";
import SliderChev2_9 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_9.jpg";
import SliderChev2_10 from "../assets/grid/Chevrolet/Clouthier/SliderChev2_10.jpg";

import SliderChev3_1 from "../assets/grid/Chevrolet/NaosaAbastos/SliderChev3_1.png";
import SliderChev3_2 from "../assets/grid/Chevrolet/NaosaAbastos/SliderChev3_2.png";
import SliderChev3_3 from "../assets/grid/Chevrolet/NaosaAbastos/SliderChev3_3.png";
import SliderChev3_4 from "../assets/grid/Chevrolet/NaosaAbastos/SliderChev3_4.png";
import SliderChev3_5 from "../assets/grid/Chevrolet/NaosaAbastos/SliderChev3_5.png";

import SliderChev4_1 from "../assets/grid/Chevrolet/SantaAnita/SliderChev4_1.jpg";
import SliderChev4_2 from "../assets/grid/Chevrolet/SantaAnita/SliderChev4_2.jpg";
import SliderChev4_3 from "../assets/grid/Chevrolet/SantaAnita/SliderChev4_3.jpg";

import SliderChev5_1 from "../assets/grid/Chevrolet/Tepic/SliderChev5_1.png";
import SliderChev5_2 from "../assets/grid/Chevrolet/Tepic/SliderChev5_2.png";
import SliderChev5_3 from "../assets/grid/Chevrolet/Tepic/SliderChev5_3.png";
import SliderChev5_4 from "../assets/grid/Chevrolet/Tepic/SliderChev5_4.png";
import SliderChev5_5 from "../assets/grid/Chevrolet/Tepic/SliderChev5_5.PNG";
import SliderChev5_6 from "../assets/grid/Chevrolet/Tepic/SliderChev5_6.JPG";
import SliderChev5_7 from "../assets/grid/Chevrolet/Tepic/SliderChev5_7.JPG";
import SliderChev5_8 from "../assets/grid/Chevrolet/Tepic/SliderChev5_8.JPG";
import SliderChev5_9 from "../assets/grid/Chevrolet/Tepic/SliderChev5_9.JPG";

import Toyota from "../assets/clients/Toyota_color.png";
import ToyotaBanner from "../assets/grid/Toyota/ToyotaBanner.png";
import SliderToyota1 from "../assets/grid/Toyota/Acueducto/SliderToyota1.png";
import SliderToyota2 from "../assets/grid/Toyota/Acueducto/SliderToyota2.png";
import SliderToyota3 from "../assets/grid/Toyota/Acueducto/SliderToyota3.png";
import SliderToyota4 from "../assets/grid/Toyota/Acueducto/SliderToyota4.png";
import SliderToyota5 from "../assets/grid/Toyota/Acueducto/SliderToyota5.png";
import SliderToyota6 from "../assets/grid/Toyota/Acueducto/SliderToyota6.png";
import SliderToyota7 from "../assets/grid/Toyota/Acueducto/SliderToyota7.png";

import Ford from "../assets/grid/Ford/Ford.png";
import SliderFord1_1 from "../assets/grid/Ford/Plascencia/SliderFord1_1.png";
import SliderFord1_2 from "../assets/grid/Ford/Plascencia/SliderFord1_2.png";
import SliderFord1_3 from "../assets/grid/Ford/Plascencia/SliderFord1_3.png";
import SliderFord1_4 from "../assets/grid/Ford/Plascencia/SliderFord1_4.png";
import SliderFord1_5 from "../assets/grid/Ford/Plascencia/SliderFord1_5.jpg";
import SliderFord1_6 from "../assets/grid/Ford/Plascencia/SliderFord1_6.jpg";
import SliderFord1_7 from "../assets/grid/Ford/Plascencia/SliderFord1_7.jpg";
import SliderFord1_8 from "../assets/grid/Ford/Plascencia/SliderFord1_8.jpg";

import SliderFord2_1 from "../assets/grid/Ford/Tepic/SliderFord2_1.png";
import SliderFord2_2 from "../assets/grid/Ford/Tepic/SliderFord2_2.png";
import SliderFord2_3 from "../assets/grid/Ford/Tepic/SliderFord2_3.png";
import SliderFord2_4 from "../assets/grid/Ford/Tepic/SliderFord2_4.png";

import SliderFord3_1 from "../assets/grid/Ford/Vallarta/SliderFord3_1.png";
import SliderFord3_2 from "../assets/grid/Ford/Vallarta/SliderFord3_2.png";
import SliderFord3_3 from "../assets/grid/Ford/Vallarta/SliderFord3_3.png";
import SliderFord3_4 from "../assets/grid/Ford/Vallarta/SliderFord3_4.png";
import SliderFord3_5 from "../assets/grid/Ford/Vallarta/SliderFord3_5.png";
import SliderFord3_6 from "../assets/grid/Ford/Vallarta/SliderFord3_6.png";
import SliderFord3_7 from "../assets/grid/Ford/Vallarta/SliderFord3_7.png";

import Gmc from "../assets/grid/GMC/Gmc.png";
import SliderGMC1_1 from "../assets/grid/GMC/Tepic/SliderGMC1_1.png";
import SliderGMC1_2 from "../assets/grid/GMC/Tepic/SliderGMC1_2.png";
import SliderGMC1_3 from "../assets/grid/GMC/Tepic/SliderGMC1_3.png";
import SliderGMC1_4 from "../assets/grid/GMC/Tepic/SliderGMC1_4.png";
import SliderGMC1_5 from "../assets/grid/GMC/Tepic/SliderGMC1_5.jpg";
import SliderGMC1_6 from "../assets/grid/GMC/Tepic/SliderGMC1_6.jpg";

import SliderGMC2_1 from "../assets/grid/GMC/Vallarta/SliderGMC2_1.jpeg";
import SliderGMC2_2 from "../assets/grid/GMC/Vallarta/SliderGMC2_2.jpeg";
import SliderGMC2_3 from "../assets/grid/GMC/Vallarta/SliderGMC2_3.jpeg";
import SliderGMC2_4 from "../assets/grid/GMC/Vallarta/SliderGMC2_4.jpeg";
import SliderGMC2_5 from "../assets/grid/GMC/Vallarta/SliderGMC2_5.jpeg";
import SliderGMC2_6 from "../assets/grid/GMC/Vallarta/SliderGMC2_6.jpeg";
import SliderGMC2_7 from "../assets/grid/GMC/Vallarta/SliderGMC2_7.jpeg";

import Hyundai from "../assets/grid/Hyundai/Hyundai.png";
import SliderHyundai1_1 from "../assets/grid/Hyundai/Colima/SliderHyundai1_1.png";
import SliderHyundai1_2 from "../assets/grid/Hyundai/Colima/SliderHyundai1_2.png";
import SliderHyundai1_3 from "../assets/grid/Hyundai/Colima/SliderHyundai1_3.png";
import SliderHyundai1_4 from "../assets/grid/Hyundai/Colima/SliderHyundai1_4.png";

import SliderHyundai2_1 from "../assets/grid/Hyundai/Vallarta/SliderHyundai2_1.png";
import SliderHyundai2_2 from "../assets/grid/Hyundai/Vallarta/SliderHyundai2_2.png";
import SliderHyundai2_3 from "../assets/grid/Hyundai/Vallarta/SliderHyundai2_3.png";
import SliderHyundai2_4 from "../assets/grid/Hyundai/Vallarta/SliderHyundai2_4.png";

import Nissan from "../assets/grid/Nissan/Nissan.png";
import SliderNissan1_1 from "../assets/grid/Nissan/Country/SliderNissan1_1.png";
import SliderNissan1_2 from "../assets/grid/Nissan/Country/SliderNissan1_2.png";
import SliderNissan1_3 from "../assets/grid/Nissan/Country/SliderNissan1_3.png";
import SliderNissan1_4 from "../assets/grid/Nissan/Country/SliderNissan1_4.png";
import SliderNissan1_5 from "../assets/grid/Nissan/Country/SliderNissan1_5.png";
import SliderNissan1_6 from "../assets/grid/Nissan/Country/SliderNissan1_6.png";

import Radial from "../assets/grid/Radial/Radial.png";
import SliderRadial1_1 from "../assets/grid/Radial/8Julio/SliderRadial1_1.png";
import SliderRadial1_2 from "../assets/grid/Radial/8Julio/SliderRadial1_2.png";
import SliderRadial1_3 from "../assets/grid/Radial/8Julio/SliderRadial1_3.png";
import SliderRadial1_4 from "../assets/grid/Radial/8Julio/SliderRadial1_4.png";

import SliderRadial2_1 from "../assets/grid/Radial/CentralCamionera/SliderRadial2_1.jpeg";
import SliderRadial2_2 from "../assets/grid/Radial/CentralCamionera/SliderRadial2_2.jpeg";
import SliderRadial2_3 from "../assets/grid/Radial/CentralCamionera/SliderRadial2_3.jpeg";

import SliderRadial3_1 from "../assets/grid/Radial/Copernico/SliderRadial3_1.png";
import SliderRadial3_2 from "../assets/grid/Radial/Copernico/SliderRadial3_2.png";
import SliderRadial3_3 from "../assets/grid/Radial/Copernico/SliderRadial3_3.png";
import SliderRadial3_4 from "../assets/grid/Radial/Copernico/SliderRadial3_4.png";

import SliderRadial4_1 from "../assets/grid/Radial/Country/SliderRadial4_1.png";
import SliderRadial4_2 from "../assets/grid/Radial/Country/SliderRadial4_2.png";
import SliderRadial4_3 from "../assets/grid/Radial/Country/SliderRadial4_3.png";
import SliderRadial4_4 from "../assets/grid/Radial/Country/SliderRadial4_4.png";

import SliderRadial5_1 from "../assets/grid/Radial/Eduwiges/SliderRadial5_1.png";
import SliderRadial5_2 from "../assets/grid/Radial/Eduwiges/SliderRadial5_2.png";
import SliderRadial5_3 from "../assets/grid/Radial/Eduwiges/SliderRadial5_3.png";

import SliderRadial6_1 from "../assets/grid/Radial/EulogioParra/SliderRadial6_1.png";
import SliderRadial6_2 from "../assets/grid/Radial/EulogioParra/SliderRadial6_2.png";
import SliderRadial6_3 from "../assets/grid/Radial/EulogioParra/SliderRadial6_3.jpg";
import SliderRadial6_4 from "../assets/grid/Radial/EulogioParra/SliderRadial6_4.jpg";

import SliderRadial7_1 from "../assets/grid/Radial/GonzalezGallo/SliderRadial7_1.png";
import SliderRadial7_2 from "../assets/grid/Radial/GonzalezGallo/SliderRadial7_2.png";
import SliderRadial7_3 from "../assets/grid/Radial/GonzalezGallo/SliderRadial7_3.png";
import SliderRadial7_4 from "../assets/grid/Radial/GonzalezGallo/SliderRadial7_4.png";

import SliderRadial8_1 from "../assets/grid/Radial/Guadalupe/SliderRadial8_1.png";
import SliderRadial8_2 from "../assets/grid/Radial/Guadalupe/SliderRadial8_2.png";
import SliderRadial8_3 from "../assets/grid/Radial/Guadalupe/SliderRadial8_3.png";

import SliderRadial9_1 from "../assets/grid/Radial/LazaroCardenas/SliderRadial9_1.png";
import SliderRadial9_2 from "../assets/grid/Radial/LazaroCardenas/SliderRadial9_2.png";
import SliderRadial9_3 from "../assets/grid/Radial/LazaroCardenas/SliderRadial9_3.png";

import SliderRadial10_1 from "../assets/grid/Radial/Patria/SliderRadial10_1.png";
import SliderRadial10_2 from "../assets/grid/Radial/Patria/SliderRadial10_2.png";
import SliderRadial10_3 from "../assets/grid/Radial/Patria/SliderRadial10_3.png";

import SliderRadial11_1 from "../assets/grid/Radial/Providencia/SliderRadial11_1.png";
import SliderRadial11_2 from "../assets/grid/Radial/Providencia/SliderRadial11_2.png";
import SliderRadial11_3 from "../assets/grid/Radial/Providencia/SliderRadial11_3.png";

import SliderRadial12_1 from "../assets/grid/Radial/Revolucion/SliderRadial12_1.png";
import SliderRadial12_2 from "../assets/grid/Radial/Revolucion/SliderRadial12_2.png";
import SliderRadial12_3 from "../assets/grid/Radial/Revolucion/SliderRadial12_3.png";
import SliderRadial12_4 from "../assets/grid/Radial/Revolucion/SliderRadial12_4.png";

import Volvo from "../assets/grid/Volvo/Volvo.png";
import SliderVolvo1_1 from "../assets/grid/Volvo/Vallarta/SliderVolvo1_1.png";
import SliderVolvo1_2 from "../assets/grid/Volvo/Vallarta/SliderVolvo1_2.png";
import SliderVolvo1_3 from "../assets/grid/Volvo/Vallarta/SliderVolvo1_3.png";

import Almer from "../assets/grid/Almer/Almer.png";
import SliderAlmer1 from "../assets/grid/Almer/Fachadas/SliderAlmer1.png";
import SliderAlmer2 from "../assets/grid/Almer/Fachadas/SliderAlmer2.png";
import SliderAlmer3 from "../assets/grid/Almer/Fachadas/SliderAlmer3.png";
import SliderAlmer4 from "../assets/grid/Almer/Fachadas/SliderAlmer4.png";
import SliderAlmer5 from "../assets/grid/Almer/Fachadas/SliderAlmer5.png";
import SliderAlmer6 from "../assets/grid/Almer/Fachadas/SliderAlmer6.png";
import SliderAlmer7 from "../assets/grid/Almer/Fachadas/SliderAlmer7.jpeg";
import SliderAlmer8 from "../assets/grid/Almer/Fachadas/SliderAlmer8.jpeg";
import SliderAlmer9 from "../assets/grid/Almer/Fachadas/SliderAlmer9.jpeg";
import SliderAlmer10 from "../assets/grid/Almer/Fachadas/SliderAlmer10.jpeg";
import SliderAlmer11 from "../assets/grid/Almer/Fachadas/SliderAlmer11.jpeg";
import SliderAlmer12 from "../assets/grid/Almer/Fachadas/SliderAlmer12.jpeg";
import SliderAlmer13 from "../assets/grid/Almer/Fachadas/SliderAlmer13.jpeg";
import SliderAlmer14 from "../assets/grid/Almer/Fachadas/SliderAlmer14.jpeg";
import SliderAlmer15 from "../assets/grid/Almer/Fachadas/SliderAlmer15.jpeg";

import Atlas from "../assets/grid/Atlas/Atlas.png";
import SliderAtlas1 from "../assets/grid/Atlas/Fachadas/SliderAtlas1.jpg";
import SliderAtlas2 from "../assets/grid/Atlas/Fachadas/SliderAtlas2.jpg";
import SliderAtlas3 from "../assets/grid/Atlas/Fachadas/SliderAtlas3.jpg";
import SliderAtlas4 from "../assets/grid/Atlas/Fachadas/SliderAtlas4.jpg";
import SliderAtlas5 from "../assets/grid/Atlas/Fachadas/SliderAtlas5.jpg";
import SliderAtlas6 from "../assets/grid/Atlas/Fachadas/SliderAtlas6.jpg";

import Cid from "../assets/grid/CID/Cid.png";
import SliderCid1 from "../assets/grid/CID/Fachadas/SliderCid1.png";
import SliderCid2 from "../assets/grid/CID/Fachadas/SliderCid2.png";
import SliderCid3 from "../assets/grid/CID/Fachadas/SliderCid3.png";
import SliderCid4 from "../assets/grid/CID/Fachadas/SliderCid4.png";
import SliderCid5 from "../assets/grid/CID/Fachadas/SliderCid5.png";
import SliderCid6 from "../assets/grid/CID/Fachadas/SliderCid6.png";
import SliderCid7 from "../assets/grid/CID/Fachadas/SliderCid7.jpeg";

import Copachisa from "../assets/grid/Copachisa/Copachisa.png";
import SliderCopachisa1 from "../assets/grid/Copachisa/Naves/SliderCopachisa1.jpg";
import SliderCopachisa2 from "../assets/grid/Copachisa/Naves/SliderCopachisa2.jpg";
import SliderCopachisa3 from "../assets/grid/Copachisa/Naves/SliderCopachisa3.jpg";
import SliderCopachisa4 from "../assets/grid/Copachisa/Naves/SliderCopachisa4.jpg";
import SliderCopachisa5 from "../assets/grid/Copachisa/Naves/SliderCopachisa5.jpg";
import SliderCopachisa6 from "../assets/grid/Copachisa/Naves/SliderCopachisa6.jpg";
import SliderCopachisa7 from "../assets/grid/Copachisa/Naves/SliderCopachisa7.jpg";

import Cucea from "../assets/grid/Cucea/Cucea.png";
import SliderCucea1 from "../assets/grid/Cucea/Fachadas/SliderCucea1.JPG";
import SliderCucea2 from "../assets/grid/Cucea/Fachadas/SliderCucea2.JPG";
import SliderCucea3 from "../assets/grid/Cucea/Fachadas/SliderCucea3.JPG";
import SliderCucea4 from "../assets/grid/Cucea/Fachadas/SliderCucea4.JPG";
import SliderCucea5 from "../assets/grid/Cucea/Fachadas/SliderCucea5.JPG";
import SliderCucea6 from "../assets/grid/Cucea/Fachadas/SliderCucea6.JPG";
import SliderCucea7 from "../assets/grid/Cucea/Fachadas/SliderCucea7.JPG";
import SliderCucea8 from "../assets/grid/Cucea/Fachadas/SliderCucea8.JPG";

import FC from "../assets/grid/FerreteriasCalzada/FC.png";
import SliderFC1 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC1.jpg";
import SliderFC2 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC2.jpg";
import SliderFC3 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC3.jpg";
import SliderFC4 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC4.jpg";
import SliderFC5 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC5.jpg";
import SliderFC6 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC6.jpg";
import SliderFC7 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC7.jpg";
import SliderFC8 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC8.jpg";
import SliderFC9 from "../assets/grid/FerreteriasCalzada/Sanitizacion/SliderFC9.jpeg";

import Fonacot from "../assets/grid/Fonacot/Fonacot.png";
import SliderFonacot1 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot1.jpg";
import SliderFonacot2 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot2.jpg";
import SliderFonacot3 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot3.jpg";
import SliderFonacot4 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot4.jpg";
import SliderFonacot5 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot5.jpeg";
import SliderFonacot6 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot6.jpeg";
import SliderFonacot7 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot7.jpeg";
import SliderFonacot8 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot8.jpeg";
import SliderFonacot9 from "../assets/grid/Fonacot/Sanitizacion/SliderFonacot9.jpeg";

import Gig from "../assets/grid/GIG/GIG.png";
import SliderGig1 from "../assets/grid/GIG/Fachadas/SliderGig1.png";
import SliderGig2 from "../assets/grid/GIG/Fachadas/SliderGig2.png";
import SliderGig3 from "../assets/grid/GIG/Fachadas/SliderGig3.png";
import SliderGig4 from "../assets/grid/GIG/Fachadas/SliderGig4.png";
import SliderGig5 from "../assets/grid/GIG/Fachadas/SliderGig5.png";
import SliderGig6 from "../assets/grid/GIG/Fachadas/SliderGig6.jpg";
import SliderGig7 from "../assets/grid/GIG/Fachadas/SliderGig7.jpg";
import SliderGig8 from "../assets/grid/GIG/Fachadas/SliderGig8.jpg";

import Gonbar from "../assets/grid/Gonbar/Gonbar.png";
import SliderGonbar1 from "../assets/grid/Gonbar/Sanitizacion/SliderGonbar1.jpg";
import SliderGonbar2 from "../assets/grid/Gonbar/Sanitizacion/SliderGonbar2.jpg";
import SliderGonbar3 from "../assets/grid/Gonbar/Sanitizacion/SliderGonbar3.jpg";
import SliderGonbar4 from "../assets/grid/Gonbar/Sanitizacion/SliderGonbar4.jpg";
import SliderGonbar5 from "../assets/grid/Gonbar/Sanitizacion/SliderGonbar5.jpg";

import Merkabastos from "../assets/grid/Merkabastos/Merkabastos.png";
import SliderMerka1 from "../assets/grid/Merkabastos/Fachadas/SliderMerka1.png";
import SliderMerka2 from "../assets/grid/Merkabastos/Fachadas/SliderMerka2.png";
import SliderMerka3 from "../assets/grid/Merkabastos/Fachadas/SliderMerka3.png";
import SliderMerka4 from "../assets/grid/Merkabastos/Fachadas/SliderMerka4.png";
import SliderMerka5 from "../assets/grid/Merkabastos/Fachadas/SliderMerka5.png";
import SliderMerka6 from "../assets/grid/Merkabastos/Fachadas/SliderMerka6.png";

import MontePiedad from "../assets/grid/MontePiedad/MontePiedad.png";
import SliderMonte1 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte1.jpg";
import SliderMonte2 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte2.jpg";
import SliderMonte3 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte3.jpg";
import SliderMonte4 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte4.jpg";
import SliderMonte5 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte5.jpg";
import SliderMonte6 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte6.jpg";
import SliderMonte7 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte7.jpg";
import SliderMonte8 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte8.jpg";
import SliderMonte9 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte9.jpg";
import SliderMonte10 from "../assets/grid/MontePiedad/Sanitizacion/SliderMonte10.jpeg";

import Ardilla from "../assets/grid/PlasticosArdilla/Ardilla.png";
import SliderArdilla1 from "../assets/grid/PlasticosArdilla/Fachadas/SliderArdilla1.png";
import SliderArdilla2 from "../assets/grid/PlasticosArdilla/Fachadas/SliderArdilla2.jpg";
import SliderArdilla3 from "../assets/grid/PlasticosArdilla/Fachadas/SliderArdilla3.png";
import SliderArdilla4 from "../assets/grid/PlasticosArdilla/Fachadas/SliderArdilla4.png";
import SliderArdilla5 from "../assets/grid/PlasticosArdilla/Fachadas/SliderArdilla5.png";
import SliderArdilla6 from "../assets/grid/PlasticosArdilla/Fachadas/SliderArdilla6.png";

import Radec from "../assets/grid/Radec/Radec.png";
import SliderRadec1 from "../assets/grid/Radec/Fachadas/SliderRadec1.png";
import SliderRadec2 from "../assets/grid/Radec/Fachadas/SliderRadec2.png";
import SliderRadec3 from "../assets/grid/Radec/Fachadas/SliderRadec3.png";
import SliderRadec4 from "../assets/grid/Radec/Fachadas/SliderRadec4.png";

import Espolon from "../assets/grid/TequilaEspolon/Espolon.png";
import SliderEspolon1 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon1.jpg";
import SliderEspolon2 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon2.jpg";
import SliderEspolon3 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon3.jpg";
import SliderEspolon4 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon4.jpg";
import SliderEspolon5 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon5.jpg";
import SliderEspolon6 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon6.jpg";
import SliderEspolon7 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon7.jpg";
import SliderEspolon8 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon8.jpg";
import SliderEspolon9 from "../assets/grid/TequilaEspolon/Naves/SliderEspolon9.jpg";

import Terra from "../assets/grid/Terra/Terra.png";
import SliderTerra1 from "../assets/grid/Terra/Sanitizacion/SliderTerra1.jpg";
import SliderTerra2 from "../assets/grid/Terra/Sanitizacion/SliderTerra2.jpg";
import SliderTerra3 from "../assets/grid/Terra/Sanitizacion/SliderTerra3.jpg";
import SliderTerra4 from "../assets/grid/Terra/Sanitizacion/SliderTerra4.jpg";
import SliderTerra5 from "../assets/grid/Terra/Sanitizacion/SliderTerra5.jpg";
import SliderTerra6 from "../assets/grid/Terra/Sanitizacion/SliderTerra6.jpg";

import Upc from "../assets/grid/UnidadPatologia/UPC.png";
import SliderUpc1 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc1.png";
import SliderUpc2 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc2.png";
import SliderUpc3 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc3.png";
import SliderUpc4 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc4.png";
import SliderUpc5 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc5.png";
import SliderUpc6 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc6.png";
import SliderUpc7 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc7.png";
import SliderUpc8 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc8.png";
import SliderUpc9 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc9.png";
import SliderUpc10 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc10.png";
import SliderUpc11 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc11.png";
import SliderUpc12 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc12.png";
import SliderUpc13 from "../assets/grid/UnidadPatologia/Fachadas/SliderUpc13.png";

import Voit from "../assets/grid/VOIT/VOIT.png";
import SliderVoit1 from "../assets/grid/VOIT/Sanitizacion/SliderVoit1.png";
import SliderVoit2 from "../assets/grid/VOIT/Sanitizacion/SliderVoit2.png";
import SliderVoit3 from "../assets/grid/VOIT/Sanitizacion/SliderVoit3.png";

export const gridData = [
  {
    id: 1,
    title: "FLEX",
    img: Flex,
    hoverImg: FlexBanner,
    bannerImg: FlexBanner,
    proyecto1: [
      {
        nombreProyecto: "Desincrustado de cristales",
        sliderImgs: [SliderFlex1_1, SliderFlex1_2, SliderFlex1_3],
        sliderImgs2: [
          SliderFlex1_1,
          SliderFlex1_2,
          SliderFlex1_3,
          SliderFlex1_4,
          SliderFlex1_5,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Fachadas monumentales",
        sliderImgs: [SliderFlex2_1, SliderFlex2_2, SliderFlex2_3],
        sliderImgs2: [
          SliderFlex2_1,
          SliderFlex2_2,
          SliderFlex2_3,
          SliderFlex2_4,
          SliderFlex2_5,
        ],
      },
    ],
    proyecto3: [
      {
        nombreProyecto: "Naves Industriales",
        sliderImgs: [SliderFlex3_1, SliderFlex3_2, SliderFlex3_3],
        sliderImgs2: [
          SliderFlex3_1,
          SliderFlex3_2,
          SliderFlex3_3,
          SliderFlex3_4,
          SliderFlex3_5,
          SliderFlex3_6,
        ],
      },
    ],
    proyecto4: [
      {
        nombreProyecto: "Paneles Solares",
        sliderImgs: [SliderFlex4_1, SliderFlex4_2, SliderFlex4_3],
        sliderImgs2: [
          SliderFlex4_1,
          SliderFlex4_2,
          SliderFlex4_3,
          SliderFlex4_4,
          SliderFlex4_5,
          SliderFlex4_6,
          SliderFlex4_7,
        ],
      },
    ],
    proyecto5: [
      {
        nombreProyecto: "Pisos Tecnicos",
        sliderImgs: [SliderFlex5_1, SliderFlex5_2, SliderFlex5_3],
        sliderImgs2: [
          SliderFlex5_1,
          SliderFlex5_2,
          SliderFlex5_3,
          SliderFlex5_4,
          SliderFlex5_5,
          SliderFlex5_6,
          SliderFlex5_7,
          SliderFlex5_8,
          SliderFlex5_9,
          SliderFlex5_10,
          SliderFlex5_11,
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Maver",
    img: Maver,
    hoverImg: MaverBanner,
    bannerImg: MaverBanner,
    proyecto1: [
      {
        nombreProyecto: "Fachadas monumentales",
        sliderImgs: [
          SliderMaver1_1,
          SliderMaver1_2,
          SliderMaver1_3,
          SliderMaver1_4,
        ],
        sliderImgs2: [
          SliderMaver1_1,
          SliderMaver1_2,
          SliderMaver1_3,
          SliderMaver1_4,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Almacenes",
        sliderImgs: [
          SliderMaver2_1,
          SliderMaver2_2,
          SliderMaver2_3,
          SliderMaver2_4,
          SliderMaver2_5,
          SliderMaver2_6,
          SliderMaver2_7,
          SliderMaver2_8,
          SliderMaver2_9,
        ],
        sliderImgs2: [
          SliderMaver2_1,
          SliderMaver2_2,
          SliderMaver2_3,
          SliderMaver2_4,
          SliderMaver2_5,
          SliderMaver2_6,
          SliderMaver2_7,
          SliderMaver2_8,
          SliderMaver2_9,
        ],
      },
    ],
    proyecto3: [
      {
        nombreProyecto: "Racks",
        sliderImgs: [SliderMaver3_1],
        sliderImgs2: [SliderMaver3_1],
      },
    ],
    proyecto4: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [
          SliderMaver4_1,
          SliderMaver4_2,
          SliderMaver4_3,
          SliderMaver4_4,
          SliderMaver4_5,
          SliderMaver4_6,
        ],
        sliderImgs2: [
          SliderMaver4_1,
          SliderMaver4_2,
          SliderMaver4_3,
          SliderMaver4_4,
          SliderMaver4_5,
          SliderMaver4_6,
        ],
      },
    ],
  },
  {
    id: 3,
    title: "FrenesiusKavi",
    img: FrenesiusKavi,
    hoverImg: FrenesiusBanner,
    bannerImg: FrenesiusBanner,
    proyecto1: [
      {
        nombreProyecto: "PisosTecnicos",
        sliderImgs: [SliderFK1, SliderFK2, SliderFK3],
        sliderImgs2: [
          SliderFK1,
          SliderFK2,
          SliderFK3,
          SliderFK4,
          SliderFK5,
          SliderFK6,
          SliderFK7,
          SliderFK8,
          SliderFK9,
          SliderFK10,
          SliderFK11,
          SliderFK12,
          SliderFK13,
          SliderFK14,
          SliderFK15,
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Kia",
    img: Kia,
    hoverImg: KiaBanner,
    bannerImg: KiaBanner,
    proyecto1: [
      {
        nombreProyecto: "Colima",
        sliderImgs: [
          SliderKia1_1,
          SliderKia1_2,
          SliderKia1_3,
          SliderKia1_4,
          SliderKia1_5,
          SliderKia1_6,
        ],
        sliderImgs2: [
          SliderKia1_1,
          SliderKia1_2,
          SliderKia1_3,
          SliderKia1_4,
          SliderKia1_5,
          SliderKia1_6,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Galerias",
        sliderImgs: [SliderKia2_1, SliderKia2_2, SliderKia2_3, SliderKia2_4],
        sliderImgs2: [SliderKia2_1, SliderKia2_2, SliderKia2_3, SliderKia2_4],
      },
    ],
    proyecto3: [
      {
        nombreProyecto: "Mazatlan",
        sliderImgs: [SliderKia3_1, SliderKia3_2, SliderKia3_3],
        sliderImgs2: [SliderKia3_1, SliderKia3_2, SliderKia3_3],
      },
    ],
    proyecto4: [
      {
        nombreProyecto: "Tepic",
        sliderImgs: [
          SliderKia4_1,
          SliderKia4_2,
          SliderKia4_3,
          SliderKia4_4,
          SliderKia4_5,
          SliderKia4_6,
        ],
        sliderImgs2: [
          SliderKia4_1,
          SliderKia4_2,
          SliderKia4_3,
          SliderKia4_4,
          SliderKia4_5,
          SliderKia4_6,
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Chevrolet",
    img: Chevrolet,
    hoverImg: ChevroletBanner,
    bannerImg: ChevroletBanner,
    proyecto1: [
      {
        nombreProyecto: "Autonova",
        sliderImgs: [SliderChev1_1, SliderChev1_2, SliderChev1_3],
        sliderImgs2: [
          SliderChev1_1,
          SliderChev1_2,
          SliderChev1_3,
          SliderChev1_4,
          SliderChev1_5,
          SliderChev1_6,
          SliderChev1_7,
          SliderChev1_8,
          SliderChev1_9,
          SliderChev1_10,
          SliderChev1_11,
          SliderChev1_12,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Clouthier",
        sliderImgs: [SliderChev2_1, SliderChev2_2, SliderChev2_3],
        sliderImgs2: [
          SliderChev2_1,
          SliderChev2_2,
          SliderChev2_3,
          SliderChev2_4,
          SliderChev2_5,
          SliderChev2_6,
          SliderChev2_7,
          SliderChev2_8,
          SliderChev2_9,
          SliderChev2_10,
        ],
      },
    ],
    proyecto3: [
      {
        nombreProyecto: "Naosa Abastos",
        sliderImgs: [SliderChev3_1, SliderChev3_2, SliderChev3_3],
        sliderImgs2: [
          SliderChev3_1,
          SliderChev3_2,
          SliderChev3_3,
          SliderChev3_4,
          SliderChev3_5,
        ],
      },
    ],
    proyecto4: [
      {
        nombreProyecto: "Santa Anita",
        sliderImgs: [SliderChev4_1, SliderChev4_2, SliderChev4_3],
        sliderImgs2: [SliderChev4_1, SliderChev4_2, SliderChev4_3],
      },
    ],
    proyecto5: [
      {
        nombreProyecto: "Tepic",
        sliderImgs: [SliderChev5_1, SliderChev5_2, SliderChev5_3],
        sliderImgs2: [
          SliderChev5_1,
          SliderChev5_2,
          SliderChev5_3,
          SliderChev5_4,
          SliderChev5_5,
          SliderChev5_6,
          SliderChev5_7,
          SliderChev5_8,
          SliderChev5_9,
        ],
      },
    ],
  },
  {
    id: 6,
    title: "Toyota",
    img: Toyota,
    hoverImg: ToyotaBanner,
    bannerImg: ToyotaBanner,
    proyecto1: [
      {
        nombreProyecto: "Acueducto",
        sliderImgs: [
          SliderToyota1,
          SliderToyota2,
          SliderToyota3,
          SliderToyota4,
          SliderToyota5,
          SliderToyota6,
          SliderToyota7,
        ],
        sliderImgs2: [
          SliderToyota1,
          SliderToyota2,
          SliderToyota3,
          SliderToyota4,
          SliderToyota5,
          SliderToyota6,
          SliderToyota7,
        ],
      },
    ],
  },
  {
    id: 7,
    title: "Ford",
    img: Ford,
    hoverImg: SliderFord2_4,
    bannerImg: SliderFord2_4,
    proyecto1: [
      {
        nombreProyecto: "Plasencia",
        sliderImgs: [SliderFord1_1, SliderFord1_2, SliderFord1_3],
        sliderImgs2: [
          SliderFord1_1,
          SliderFord1_2,
          SliderFord1_3,
          SliderFord1_4,
          SliderFord1_5,
          SliderFord1_6,
          SliderFord1_7,
          SliderFord1_8,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Tepic",
        sliderImgs: [SliderFord2_1, SliderFord2_2, SliderFord2_3],
        sliderImgs2: [
          SliderFord2_1,
          SliderFord2_2,
          SliderFord2_3,
          SliderFord2_4,
        ],
      },
    ],
    proyecto3: [
      {
        nombreProyecto: "Puerto Vallarta",
        sliderImgs: [SliderFord3_1, SliderFord3_2, SliderFord3_3],
        sliderImgs2: [
          SliderFord3_1,
          SliderFord3_2,
          SliderFord3_3,
          SliderFord3_4,
          SliderFord3_5,
          SliderFord3_6,
          SliderFord3_7,
        ],
      },
    ],
  },
  {
    id: 8,
    title: "Gmc",
    img: Gmc,
    hoverImg: SliderGMC1_5,
    bannerImg: SliderGMC1_5,
    proyecto1: [
      {
        nombreProyecto: "Tepic",
        sliderImgs: [SliderGMC1_1, SliderGMC1_2, SliderGMC1_3],
        sliderImgs2: [
          SliderGMC1_1,
          SliderGMC1_2,
          SliderGMC1_3,
          SliderGMC1_4,
          SliderGMC1_5,
          SliderGMC1_6,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Solana Vallarta",
        sliderImgs: [SliderGMC2_1, SliderGMC2_2, SliderGMC2_3],
        sliderImgs2: [
          SliderGMC2_1,
          SliderGMC2_2,
          SliderGMC2_3,
          SliderGMC2_4,
          SliderGMC2_5,
          SliderGMC2_6,
          SliderGMC2_7,
        ],
      },
    ],
  },
  {
    id: 9,
    title: "Hyundai",
    img: Hyundai,
    hoverImg: SliderHyundai1_4,
    bannerImg: SliderHyundai1_4,
    proyecto1: [
      {
        nombreProyecto: "Colima",
        sliderImgs: [
          SliderHyundai1_1,
          SliderHyundai1_2,
          SliderHyundai1_3,
          SliderHyundai1_4,
        ],
        sliderImgs2: [
          SliderHyundai1_1,
          SliderHyundai1_2,
          SliderHyundai1_3,
          SliderHyundai1_4,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Puerto Vallarta",
        sliderImgs: [
          SliderHyundai2_1,
          SliderHyundai2_2,
          SliderHyundai2_3,
          SliderHyundai2_4,
        ],
        sliderImgs2: [
          SliderHyundai2_1,
          SliderHyundai2_2,
          SliderHyundai2_3,
          SliderHyundai2_4,
        ],
      },
    ],
  },
  {
    id: 10,
    title: "Nissan",
    img: Nissan,
    hoverImg: SliderNissan1_3,
    bannerImg: SliderNissan1_3,
    proyecto1: [
      {
        nombreProyecto: "Country",
        sliderImgs: [
          SliderNissan1_1,
          SliderNissan1_2,
          SliderNissan1_3,
          SliderNissan1_4,
          SliderNissan1_5,
          SliderNissan1_6,
        ],
        sliderImgs2: [
          SliderNissan1_1,
          SliderNissan1_2,
          SliderNissan1_3,
          SliderNissan1_4,
          SliderNissan1_5,
          SliderNissan1_6,
        ],
      },
    ],
  },
  {
    id: 11,
    title: "Radial",
    img: Radial,
    hoverImg: SliderRadial2_3,
    bannerImg: SliderRadial2_2,
    proyecto1: [
      {
        nombreProyecto: "8 de Julio",
        sliderImgs: [
          SliderRadial1_1,
          SliderRadial1_2,
          SliderRadial1_3,
          SliderRadial1_4,
        ],
        sliderImgs2: [
          SliderRadial1_1,
          SliderRadial1_2,
          SliderRadial1_3,
          SliderRadial1_4,
        ],
      },
    ],
    proyecto2: [
      {
        nombreProyecto: "Central Camionera",
        sliderImgs: [SliderRadial2_1, SliderRadial2_2, SliderRadial2_3],
        sliderImgs2: [SliderRadial2_1, SliderRadial2_2, SliderRadial2_3],
      },
    ],
    proyecto3: [
      {
        nombreProyecto: "Copernico",
        sliderImgs: [
          SliderRadial3_1,
          SliderRadial3_2,
          SliderRadial3_3,
          SliderRadial3_4,
        ],
        sliderImgs2: [
          SliderRadial3_1,
          SliderRadial3_2,
          SliderRadial3_3,
          SliderRadial3_4,
        ],
      },
    ],
    proyecto4: [
      {
        nombreProyecto: "Country",
        sliderImgs: [
          SliderRadial4_1,
          SliderRadial4_2,
          SliderRadial4_3,
          SliderRadial4_4,
        ],
        sliderImgs2: [
          SliderRadial4_1,
          SliderRadial4_2,
          SliderRadial4_3,
          SliderRadial4_4,
        ],
      },
    ],
    proyecto5: [
      {
        nombreProyecto: "Eduwiges",
        sliderImgs: [SliderRadial5_1, SliderRadial5_2, SliderRadial5_3],
        sliderImgs2: [SliderRadial5_1, SliderRadial5_2, SliderRadial5_3],
      },
    ],
    proyecto6: [
      {
        nombreProyecto: "Eulogio Parra",
        sliderImgs: [
          SliderRadial6_1,
          SliderRadial6_2,
          SliderRadial6_3,
          SliderRadial6_4,
        ],
        sliderImgs2: [
          SliderRadial6_1,
          SliderRadial6_2,
          SliderRadial6_3,
          SliderRadial6_4,
        ],
      },
    ],
    proyecto7: [
      {
        nombreProyecto: "Gonzalez Gallo",
        sliderImgs: [
          SliderRadial7_1,
          SliderRadial7_2,
          SliderRadial7_3,
          SliderRadial7_4,
        ],
        sliderImgs2: [
          SliderRadial7_1,
          SliderRadial7_2,
          SliderRadial7_3,
          SliderRadial7_4,
        ],
      },
    ],
    proyecto8: [
      {
        nombreProyecto: "Guadalupe",
        sliderImgs: [SliderRadial8_1, SliderRadial8_2, SliderRadial8_3],
        sliderImgs2: [SliderRadial8_1, SliderRadial8_2, SliderRadial8_3],
      },
    ],
    proyecto9: [
      {
        nombreProyecto: "Lazaro Cardenas",
        sliderImgs: [SliderRadial9_1, SliderRadial9_2, SliderRadial9_3],
        sliderImgs2: [SliderRadial9_1, SliderRadial9_2, SliderRadial9_3],
      },
    ],
    proyecto10: [
      {
        nombreProyecto: "Patria",
        sliderImgs: [SliderRadial10_1, SliderRadial10_2, SliderRadial10_3],
        sliderImgs2: [SliderRadial10_1, SliderRadial10_2, SliderRadial10_3],
      },
    ],
    proyecto11: [
      {
        nombreProyecto: "Providencia",
        sliderImgs: [SliderRadial11_1, SliderRadial11_2, SliderRadial11_3],
        sliderImgs2: [SliderRadial11_1, SliderRadial11_2, SliderRadial11_3],
      },
    ],
    proyecto12: [
      {
        nombreProyecto: "Revolucion",
        sliderImgs: [
          SliderRadial12_1,
          SliderRadial12_2,
          SliderRadial12_3,
          SliderRadial12_4,
        ],
        sliderImgs2: [
          SliderRadial12_1,
          SliderRadial12_2,
          SliderRadial12_3,
          SliderRadial12_4,
        ],
      },
    ],
  },
  {
    id: 12,
    title: "Volvo",
    img: Volvo,
    hoverImg: SliderVolvo1_1,
    bannerImg: SliderVolvo1_1,
    proyecto1: [
      {
        nombreProyecto: "Vallarta",
        sliderImgs: [SliderVolvo1_1, SliderVolvo1_2, SliderVolvo1_3],
        sliderImgs2: [SliderVolvo1_1, SliderVolvo1_2, SliderVolvo1_3],
      },
    ],
  },
  {
    id: 13,
    title: "Almer",
    img: Almer,
    hoverImg: SliderAlmer15,
    bannerImg: SliderAlmer15,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [SliderAlmer1, SliderAlmer2, SliderAlmer3],
        sliderImgs2: [
          SliderAlmer1,
          SliderAlmer2,
          SliderAlmer3,
          SliderAlmer4,
          SliderAlmer5,
          SliderAlmer6,
          SliderAlmer7,
          SliderAlmer8,
          SliderAlmer9,
          SliderAlmer10,
          SliderAlmer11,
          SliderAlmer12,
          SliderAlmer13,
          SliderAlmer14,
          SliderAlmer15,
        ],
      },
    ],
  },
  {
    id: 14,
    title: "Atlas",
    img: Atlas,
    hoverImg: SliderAtlas2,
    bannerImg: SliderAtlas2,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [SliderAtlas1, SliderAtlas2, SliderAtlas3],
        sliderImgs2: [
          SliderAtlas1,
          SliderAtlas2,
          SliderAtlas3,
          SliderAtlas4,
          SliderAtlas5,
          SliderAtlas6,
        ],
      },
    ],
  },
  {
    id: 15,
    title: "Cid",
    img: Cid,
    hoverImg: SliderCid5,
    bannerImg: SliderCid5,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [SliderCid1, SliderCid2, SliderCid3],
        sliderImgs2: [
          SliderCid1,
          SliderCid2,
          SliderCid3,
          SliderCid4,
          SliderCid5,
          SliderCid6,
          SliderCid7,
        ],
      },
    ],
  },
  {
    id: 16,
    title: "Copachisa",
    img: Copachisa,
    hoverImg: SliderCopachisa7,
    bannerImg: SliderCopachisa7,
    proyecto1: [
      {
        nombreProyecto: "Naves Industriales",
        sliderImgs: [SliderCopachisa1, SliderCopachisa2, SliderCopachisa3],
        sliderImgs2: [
          SliderCopachisa1,
          SliderCopachisa2,
          SliderCopachisa3,
          SliderCopachisa4,
          SliderCopachisa5,
          SliderCopachisa6,
          SliderCopachisa7,
        ],
      },
    ],
  },
  {
    id: 17,
    title: "Cucea",
    img: Cucea,
    hoverImg: SliderCucea7,
    bannerImg: SliderCucea7,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [SliderCucea1, SliderCucea2, SliderCucea3],
        sliderImgs2: [
          SliderCucea1,
          SliderCucea2,
          SliderCucea3,
          SliderCucea4,
          SliderCucea5,
          SliderCucea6,
          SliderCucea7,
          SliderCucea8,
        ],
      },
    ],
  },
  {
    id: 18,
    title: "FC",
    img: FC,
    hoverImg: SliderFC5,
    bannerImg: SliderFC5,
    proyecto1: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [SliderFC1, SliderFC2, SliderFC3],
        sliderImgs2: [
          SliderFC1,
          SliderFC2,
          SliderFC3,
          SliderFC4,
          SliderFC5,
          SliderFC6,
          SliderFC7,
          SliderFC8,
          SliderFC9,
        ],
      },
    ],
  },
  {
    id: 19,
    title: "Fonacot",
    img: Fonacot,
    hoverImg: SliderFonacot5,
    bannerImg: SliderFonacot5,
    proyecto1: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [SliderFonacot1, SliderFonacot2, SliderFonacot3],
        sliderImgs2: [
          SliderFonacot1,
          SliderFonacot2,
          SliderFonacot3,
          SliderFonacot4,
          SliderFonacot5,
          SliderFonacot6,
          SliderFonacot7,
          SliderFonacot8,
          SliderFonacot9,
        ],
      },
    ],
  },
  {
    id: 20,
    title: "Gig",
    img: Gig,
    hoverImg: SliderGig5,
    bannerImg: SliderGig5,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [SliderGig1, SliderGig2, SliderGig3],
        sliderImgs2: [
          SliderGig1,
          SliderGig2,
          SliderGig3,
          SliderGig4,
          SliderGig5,
          SliderGig6,
          SliderGig7,
          SliderGig8,
        ],
      },
    ],
  },
  {
    id: 21,
    title: "Gonbar",
    img: Gonbar,
    hoverImg: SliderGonbar4,
    bannerImg: SliderGonbar4,
    proyecto1: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [SliderGonbar1, SliderGonbar2, SliderGonbar3],
        sliderImgs2: [
          SliderGonbar1,
          SliderGonbar2,
          SliderGonbar3,
          SliderGonbar4,
          SliderGonbar5,
        ],
      },
    ],
  },
  {
    id: 22,
    title: "Merkabastos",
    img: Merkabastos,
    hoverImg: SliderMerka1,
    bannerImg: SliderMerka1,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [
          SliderMerka1,
          SliderMerka2,
          SliderMerka3,
          SliderMerka4,
          SliderMerka5,
          SliderMerka6,
        ],
        sliderImgs2: [
          SliderMerka1,
          SliderMerka2,
          SliderMerka3,
          SliderMerka4,
          SliderMerka5,
          SliderMerka6,
        ],
      },
    ],
  },
  {
    id: 23,
    title: "MontePiedad",
    img: MontePiedad,
    hoverImg: SliderMonte7,
    bannerImg: SliderMonte7,
    proyecto1: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [SliderMonte1, SliderMonte2, SliderMonte3],
        sliderImgs2: [
          SliderMonte1,
          SliderMonte2,
          SliderMonte3,
          SliderMonte4,
          SliderMonte5,
          SliderMonte6,
          SliderMonte7,
          SliderMonte8,
          SliderMonte9,
          SliderMonte10,
        ],
      },
    ],
  },
  {
    id: 24,
    title: "Ardilla",
    img: Ardilla,
    hoverImg: SliderArdilla2,
    bannerImg: SliderArdilla2,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [
          SliderArdilla1,
          SliderArdilla2,
          SliderArdilla3,
          SliderArdilla4,
          SliderArdilla5,
          SliderArdilla6,
        ],
        sliderImgs2: [
          SliderArdilla1,
          SliderArdilla2,
          SliderArdilla3,
          SliderArdilla4,
          SliderArdilla5,
          SliderArdilla6,
        ],
      },
    ],
  },
  {
    id: 25,
    title: "Radec",
    img: Radec,
    hoverImg: SliderRadec2,
    bannerImg: SliderRadec3,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [SliderRadec1, SliderRadec2, SliderRadec3, SliderRadec4],
        sliderImgs2: [SliderRadec1, SliderRadec2, SliderRadec3, SliderRadec4],
      },
    ],
  },
  {
    id: 26,
    title: "Espolon",
    img: Espolon,
    hoverImg: SliderEspolon7,
    bannerImg: SliderEspolon7,
    proyecto1: [
      {
        nombreProyecto: "Naves Industriales",
        sliderImgs: [SliderEspolon1, SliderEspolon2, SliderEspolon3],
        sliderImgs2: [
          SliderEspolon1,
          SliderEspolon2,
          SliderEspolon3,
          SliderEspolon4,
          SliderEspolon5,
          SliderEspolon6,
          SliderEspolon7,
          SliderEspolon8,
          SliderEspolon9,
        ],
      },
    ],
  },
  {
    id: 27,
    title: "Terra",
    img: Terra,
    hoverImg: SliderTerra5,
    bannerImg: SliderTerra5,
    proyecto1: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [SliderTerra1, SliderTerra2, SliderTerra3],
        sliderImgs2: [
          SliderTerra1,
          SliderTerra2,
          SliderTerra3,
          SliderTerra4,
          SliderTerra5,
          SliderTerra6,
        ],
      },
    ],
  },
  {
    id: 28,
    title: "Upc",
    img: Upc,
    hoverImg: SliderUpc2,
    bannerImg: SliderUpc6,
    proyecto1: [
      {
        nombreProyecto: "Fachadas Monumentales",
        sliderImgs: [
          SliderUpc1,
          SliderUpc2,
          SliderUpc3,
          SliderUpc4,
          SliderUpc5,
          SliderUpc6,
          SliderUpc7,
          SliderUpc8,
          SliderUpc9,
          SliderUpc10,
          SliderUpc11,
          SliderUpc12,
          SliderUpc13,
        ],
        sliderImgs2: [
          SliderUpc1,
          SliderUpc2,
          SliderUpc3,
          SliderUpc4,
          SliderUpc5,
          SliderUpc6,
          SliderUpc7,
          SliderUpc8,
          SliderUpc9,
          SliderUpc10,
          SliderUpc11,
          SliderUpc12,
          SliderUpc13,
        ],
      },
    ],
  },
  {
    id: 29,
    title: "Voit",
    img: Voit,
    hoverImg: SliderVoit3,
    bannerImg: SliderVoit3,
    proyecto1: [
      {
        nombreProyecto: "Sanitizacion",
        sliderImgs: [SliderVoit1, SliderVoit2, SliderVoit3],
        sliderImgs2: [SliderVoit1, SliderVoit2, SliderVoit3],
      },
    ],
  },
];
