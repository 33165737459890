import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Home } from './pages/Home';
import { Us } from './pages/Us';
import { OurPeople } from './pages/OurPeople';
import { Clients } from './pages/ClientsPage';
import { ClientPage } from './pages/ClientPage';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/us' element={<Us/>} />
        <Route path='/ourPeople' element={<OurPeople/>} />
        <Route path='/clients' element={<Clients/>} />
        <Route path='/clients/:id' element={<ClientPage/>} />
      </Routes>
    </div>
  );
}

export default App;