import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

import prDoneData from "../../data/prDoneData";

import "./SliderCarruselClients.css";

export const SliderCarruselClients = () => {
  return (
    <Swiper
      spaceBetween={50}
      effect={"fade"}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      slidesPerView={3}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      className="clientSwiper"
    >
      {prDoneData.map((banner) => {
        return (
          <SwiperSlide>
            <img
              className="SliderCarruselClientSlideImage"
              src={banner.img}
              alt={banner.alt}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
