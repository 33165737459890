import React from "react";

import { gridData } from "../../data/gridData";
import { useParams } from "react-router-dom";
import "./ClientBanner.css";

export const ClientBanner = () => {
  const { id } = useParams();
  const clientData = gridData.find((data) => data.id === Number(id));
  return (
    <div
      className="clientBanner-container"
      style={{
        backgroundImage: `url(${clientData?.bannerImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
      }}
    >
      <div className="clientBanner-title">{clientData?.title}</div>
    </div>
  );
};
