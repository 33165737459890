import React from 'react'
import './NumberBar.css'
import IconPhoneNumber from '../../assets/IconPhoneNumber.svg'

export const NumberBar = () => {
  return (
    <div className='numBar-container'>
        <img src={IconPhoneNumber} alt='icon phone number'/>
        <span>33 1031 5589</span>
    </div>
  )
}
