import image1 from '../assets/us/usBanner/Fondo.png';
import image2 from '../assets/us/usBanner/Fondo2.png'

const usBannerData = [
    {
        img: image1,
        alt: 'image 1'
    },
    {
        img: image2,
        alt: 'image 2'
    },
]

export default usBannerData;