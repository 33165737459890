import React, { useState }from "react";

import "./Form.css"

const Form = () => {
    const [formState, setFormState] = useState({});

    const changeHandler = (event) => {
        setFormState({ ...formState, [event.target.name]: event.target.value })
    }
    return(
        <div className="form-container">
            <form>
                <input type="text" name="Name" placeholder="Nombre" onChange={changeHandler} />
                <input type="email" name="Email" placeholder="Email"  onChange={changeHandler}/>
                <input type="tel" pattern="[0-9]{10}]" name="Phone" placeholder="Número celular / telefónico"  onChange={changeHandler}/>
                <textarea type="text" name="TellUs" placeholder="Mensaje" rows="5" cols="20"  onChange={changeHandler} />
                <div className="disclaimer">
                    Tu información no será compartida con terceros. <span>Privacidad y política.</span>
                </div>
                <input type="submit" value="Enviar" />
            </form>
        </div>
    );
};

export default Form;