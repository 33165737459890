import LimpiezaFachadas from '../assets/slider/LimpiezaFachadas.png'
import LimpiezaNaves from '../assets/slider/LimpiezaNaves.png'
import LimpiezaPisos from '../assets/slider/LimpiezaPisos.png'
import Sanitizacion from '../assets/slider/sanitizacion.png'
import LimpiezaDeRacks from '../assets/slider/limpieza-de-racks.png'
import LimpiezaDePanelesSolares from '../assets/slider/limpieza-de-paneles-solares.png'
import LimpiezaDeEstructuras from '../assets/slider/limpieza-de-estructuras.png'

const sliderData = [
    {
        img: LimpiezaFachadas,
        alt: 'LimpiezaFachadasImagen'
    },
    {
        img:LimpiezaNaves,
        alt: 'LimpiezaNavesImagen'
    },
    {
        img: LimpiezaPisos,
        alt: 'LimpiezaPisosImagen'
    },
    {
        img: Sanitizacion,
        alt: 'LimpiezaFachadasImagen'
    },
    {
        img:LimpiezaDeRacks,
        alt: 'LimpiezaNavesImagen'
    },
    {
        img: LimpiezaDePanelesSolares,
        alt: 'LimpiezaPisosImagen'
    },
    {
        img:LimpiezaDeEstructuras,
        alt: 'LimpiezaNavesImagen'
    }
]

export default sliderData;
