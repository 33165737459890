import React from "react";
import "./Home.css";
import { useState, useLayoutEffect } from "react";

//components
import { Navbar, SideNav } from "../components/Navbar/Navbar";
import { SliderCarrusel } from "../components/SliderCarrusel/SliderCarrusel";
import { Clients } from "../components/Clients/Clients";
import { FooterForm } from "../components/FooterForm/FooterForm";
import { NumberBar } from "../components/NumberBar/NumberBar";

export const Home = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [scrollThreshold, setScrollThreshold] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setScrollThreshold(window.innerHeight * 0.8);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollThreshold]);

  return (
    <div className="homePage-container">
      <div className="desktop">
        <Navbar />
      </div>
      <div className="mobile">
        <SideNav />
      </div>
      <SliderCarrusel />
      <div className="homePage-textContainer">
        <h1>NUESTROS CLIENTES</h1>
      </div>
      <Clients />
      {isVisible && <NumberBar />}
      <FooterForm />
    </div>
  );
};
