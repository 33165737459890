import React, { useState } from "react";
import "./Navbar.css";

import { NavLink } from "react-router-dom";
import ImageLogo from "../../assets/Logo.svg";
import LogoText from "../../assets/ClText.svg";
import { FaBars, FaTimes } from "react-icons/fa";

import "./SideNav.css";

export const SideNav = () => {
  const navtoTop = () => {
    window.scrollTo(0, 0);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="sidenav-container">
      <div className="navbar-iconContainer">
        <img src={ImageLogo} alt="ImageLogo" className="image-logo-navbar" />
        <img src={LogoText} alt="Logo Text" className="text-logo-navbar" />
      </div>
      <div className={`sidenav ${showNav ? "show" : ""}`}>
        <NavLink to="/" onClick={navtoTop}>
          Inicio
        </NavLink>

        <NavLink to="/us" onClick={navtoTop}>
          ¿Quiénes somos?
        </NavLink>

        <NavLink to="/ourPeople" onClick={navtoTop}>
          Nuestra gente
        </NavLink>

        <NavLink to="/clients" onClick={navtoTop}>
          Proyectos realizados
        </NavLink>
      </div>

      <div className="main-content">
        <button onClick={toggleNav}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </div>
  );
};

export const Navbar = () => {
  const navtoTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="navbar-container">
      <div className="navbar-layout">
        <div className="navbar-iconContainer">
          <img src={ImageLogo} alt="ImageLogo" className="image-logo-navbar" />
          <img src={LogoText} alt="Logo Text" className="text-logo-navbar" />
        </div>
        <div className="navbar-linkContainer">
          <ul>
            <li>
              <NavLink to="/" onClick={navtoTop}>
                Inicio
              </NavLink>
            </li>
            <li>
              <NavLink to="/us" onClick={navtoTop}>
                ¿Quiénes somos?
              </NavLink>
            </li>
            <li>
              <NavLink to="/ourPeople" onClick={navtoTop}>
                Nuestra gente
              </NavLink>
            </li>
            <li>
              <NavLink to="/clients" onClick={navtoTop}>
                Proyectos realizados
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
